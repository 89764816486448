<template>
  <b-row class="match-height">
    <b-col lg="12">
      <b-card title="Material Request Detail">
        <b-row>
          <b-col cols="12">
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            <b-button
              type="button"
              variant="primary"
              class="mr-1 float-rigth"
              @click="print()"
            >
              <feather-icon icon="PrinterIcon" size="16" class="align-middle" />
              Print
            </b-button>
            <b-button
              v-if="materialrequestIsReceived === 'Yes'"
              type="button"
              variant="primary"
              class="mr-1 float-rigth"
              @click="deliveryConfirmation()"
            >
              <feather-icon icon="PrinterIcon" size="16" class="align-middle" />
              Delivery Confirmation
            </b-button>
            <div class="devider" />
            <b-form-group>
              <label for="email">Number :</label>
              <input
                v-model="materialRequestNumber"
                type="text"
                name="Number"
                class="form-control"
                readonly
              />
            </b-form-group>
            <b-form-group>
              <label for="email">To :</label>
              <input
                v-model="materialRequestTo"
                type="text"
                name="to"
                class="form-control"
                readonly
              />
            </b-form-group>
            <b-form-group>
              <label for="email">From :</label>
              <input
                v-model="materialRequestCc"
                type="text"
                name="from"
                class="form-control"
                readonly
              />
            </b-form-group>
            <b-form-group>
              <label for="no_invoice">Reason :</label>
              <input
                v-model="materialRequestReason"
                type="text"
                name="reason"
                class="form-control"
                readonly
              />
            </b-form-group>
            <b-form-group>
              <label for="no_invoice">Items :</label>
            </b-form-group>
            <b-form-group>
              <br />
              <div v-if="materialrequestIsAcceptedBySupervisor === false">
                <b-row>
                  <b-col cols="5" class="text-center font-weight-bold">
                    Item name
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Item Unit
                  </b-col>
                  <b-col cols="4" class="text-center font-weight-bold">
                    Item QTY
                  </b-col>
                </b-row>
                <p />
                <b-row
                  v-for="item in items"
                  :key="item.material_request_detail_item_id"
                >
                  <br />
                  <b-col cols="5" class="text-center">
                    <b-list-group>
                      <b-list-group-item
                        v-if="item.item.item_picture"
                        :href="getFullUrl(item.item.item_picture)"
                        target="_blank"
                      >
                        {{ item.item.item_name }} ({{ item.item.item_code }})
                      </b-list-group-item>
                      <b-list-group-item
                        v-if="item.item.item_picture == null"
                        href="#"
                      >
                        {{ item.item.item_name }} ({{ item.item.item_code }})
                      </b-list-group-item>
                    </b-list-group> </b-col
                  ><b-col cols="2" class="text-center">
                    <b-list-group>
                      <b-list-group-item href="#">
                        {{
                          item.item.unit
                            ? capitalizeWords(item.item.unit.unit_name)
                            : "-"
                        }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="4" class="text-center">
                    <b-form-input
                      v-model="item.material_request_detail_item_qty_requested"
                      min="1"
                      value="item.material_request_detail_item_qty_requested"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="12">
                    <br />
                  </b-col>
                </b-row>
              </div>
              <div
                v-if="
                  materialrequestIsAcceptedBySupervisor === true &&
                    materialrequestIsAcceptedByWarehouse === false
                "
              >
                <b-row>
                  <b-col cols="5" class="text-center font-weight-bold">
                    Item name
                  </b-col>
                  <b-col cols="1" class="text-center font-weight-bold">
                    Item Unit
                  </b-col>
                  <b-col cols="1" class="text-center font-weight-bold">
                    Requested QTY
                  </b-col>
                  <b-col cols="1" class="text-center font-weight-bold">
                    Accepted QTY
                  </b-col>
                  <b-col cols="1" class="text-center font-weight-bold">
                    Sent QTY
                  </b-col>
                  <b-col cols="1" class="text-center font-weight-bold">
                    Received QTY
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Is Final
                  </b-col>
                </b-row>
                <p />
                <b-row
                  v-for="item in items"
                  :key="item.material_request_detail_item_id"
                >
                  <br />
                  <b-col cols="5" class="text-center">
                    <b-list-group>
                      <b-list-group-item href="#">
                        {{ item.item.item_name }} ({{ item.item.item_code }})
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="1" class="text-center">
                    <b-list-group>
                      <b-list-group-item href="#">
                        {{
                          item.item.unit
                            ? capitalizeWords(item.item.unit.unit_name)
                            : "-"
                        }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="1" class="text-center">
                    <b-form-input
                      v-model="item.material_request_detail_item_qty_requested"
                      min="1"
                      value="item.material_request_detail_item_qty_requested"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="1" class="text-center">
                    <b-form-input
                      v-model="item.material_request_detail_item_qty_accepted"
                      min="1"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="1" class="text-center">
                    <b-form-input
                      v-model="item.material_request_detail_item_qty_sent"
                      min="1"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="1" class="text-center">
                    <b-form-input
                      v-model="item.material_request_detail_item_qty_received"
                      min="1"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="2" class="text-center">
                    <b-list-group>
                      <b-list-group-item href="#">
                        {{ item.material_request_detail_is_final ? 'Ya' : 'Tidak' }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="12">
                    <br />
                  </b-col>
                </b-row>
              </div>
              <div
                v-if="
                  materialrequestIsAcceptedBySupervisor === true &&
                    materialrequestIsAcceptedByWarehouse === true &&
                    materialrequestIsReceived == 'Yes'
                "
              >
                <b-row>
                  <b-col cols="4" class="text-center font-weight-bold">
                    Item name
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Item Unit
                  </b-col>
                  <b-col cols="1" class="text-center font-weight-bold">
                    Requested QTY
                  </b-col>
                  <b-col cols="1" class="text-center font-weight-bold">
                    Accepted QTY
                  </b-col>
                  <b-col cols="1" class="text-center font-weight-bold">
                    Sent QTY
                  </b-col>
                  <b-col cols="1" class="text-center font-weight-bold">
                    Received QTY
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Is Final
                  </b-col>
                </b-row>
                <p />
                <b-row
                  v-for="item in items"
                  :key="item.material_request_detail_item_id"
                >
                  <br />
                  <b-col cols="4" class="text-center">
                    <b-list-group>
                      <b-list-group-item
                        v-if="item.item.item_picture"
                        :href="getFullUrl(item.item.item_picture)"
                        target="_blank"
                      >
                        {{ item.item.item_name }} ({{ item.item.item_code }})
                      </b-list-group-item>
                      <b-list-group-item
                        v-if="item.item.item_picture == null"
                        href="#"
                      >
                        {{ item.item.item_name }} ({{ item.item.item_code }})
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="2" class="text-center">
                    <b-list-group>
                      <b-list-group-item href="#">
                        {{
                          item.item.unit
                            ? capitalizeWords(item.item.unit.unit_name)
                            : "-"
                        }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="1" class="text-center">
                    <b-form-input
                      v-model="item.material_request_detail_item_qty_requested"
                      min="1"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="1" class="text-center">
                    <b-form-input
                      v-model="item.material_request_detail_item_qty_accepted"
                      min="1"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="1" class="text-center">
                    <b-form-input
                      v-model="item.material_request_detail_item_qty_sent"
                      min="1"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="1" class="text-center">
                    <b-form-input
                      v-model="item.material_request_detail_item_qty_received"
                      min="1"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="2" class="text-center">
                    <b-list-group>
                      <b-list-group-item href="#">
                        {{ item.material_request_detail_is_final ? 'Ya' : 'Tidak' }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="12">
                    <br />
                  </b-col>
                </b-row>
              </div>
            </b-form-group>
            <b-form-group>
              <label for="no_invoice">Status :</label>
              <vue-horizontal-timeline :items="statusItems" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Ripple from "vue-ripple-directive"
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import { ref } from "@vue/composition-api"

const VersionCheck = ""
const items = []
const chosenItem = ref(3)
const chosens = []
const statusItems = []
const materialRequestTo = ""
const materialRequestCc = ""
const materialRequestReason = ""
const materialRequestQtyRequested = 0
const materialRequestStatus = ""
const materialRequestId = ""
const materialRequestNumber = ""
const materialRequestType = ""
const materialrequestIsAcceptedBySupervisor = 0
const materialrequestIsAcceptedByWarehouse = 0
const materialrequestIsReceived = 'No'
const apiUrl = process.env.VUE_APP_API_URL
export default {
  components: {
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusItems,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      materialRequestTo,
      materialRequestCc,
      materialRequestReason,
      materialRequestQtyRequested,
      materialRequestStatus,
      materialRequestId,
      materialRequestNumber,
      materialrequestIsAcceptedBySupervisor,
      materialrequestIsAcceptedByWarehouse,
      materialrequestIsReceived,
      materialRequestType,
      apiUrl,
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
      const item = JSON.parse(localStorage.getItem("materialRequestDetail"))
      console.log(item)
      this.modalDetailShow = true
      this.materialRequestTo = item.material_request_to
      this.materialRequestCc = item.material_request_cc
      this.materialRequestReason = item.material_request_reason
      this.materialRequestId = item.material_request_id
      this.materialRequestNumber = item.material_request_number
      this.materialrequestIsAcceptedByWarehouse = item.material_request_is_accepted_by_warehouse
      this.materialrequestIsAcceptedBySupervisor = item.material_request_is_accepted_by_supervisor
      this.materialRequestType = item.material_request_is_center === true ? "Head Office" : "Local"
      this.materialrequestIsReceived = !!item.material_request_is_received === true ? "Yes" : "No"
      const id = item.material_request_id
      const obj1 = {
        title: "Created",
        content: `Material Request Has been Created By ${
          item.requester.user_fullname
        } at ${this.dateSimple(
          item.material_request_created_time,
          "YYYY-MM-DD",
        )}`,
        stepCssClass: "has-step-green",
        boxCssClass: "has-color-red",
      }
      const obj2 = {
        title: "Supervisor",
        content:
          item.material_request_is_accepted_by_supervisor === true
            ? `Material Request Has been Accepted by ${
                item.supervisor.user_fullname
              } at ${this.dateSimple(
                item.material_request_is_accepted_by_supervisor_date,
                "YYYY-MM-DD",
              )}`
            : `Material Request Has not been Accepted By Supervisor`,
        stepCssClass:
          item.material_request_is_accepted_by_supervisor === true
            ? "has-step-green"
            : "has-step-red",
      }
      let obj3 = null
      let obj4 = null
      if (item.material_request_is_center === true) {
        obj3 = {
          title: "Purchase Request",
          content:
            item.material_request_is_created_in_pr === true
              ? `Purchase Request Has been Accepted by ${
                  item.purchase_request_creator.user_fullname
                } at ${this.dateSimple(
                  item.material_request_is_created_in_pr_date,
                  "YYYY-MM-DD",
                )}`
              : `Purchase Request Has not been Created`,
          stepCssClass:
            item.material_request_is_created_in_pr === true
              ? "has-step-green"
              : "has-step-red",
        }
        obj4 = {
          title: "Purchase Order",
          content:
            item.material_request_is_created_in_po === true
              ? `Purchase Order Has been created By ${
                  item.purchase_order_creator.user_fullname
                } at ${this.dateSimple(
                  item.material_request_is_created_in_po_date,
                  "YYYY-MM-DD",
                )}`
              : `Purchase Order Has not been Created`,
          stepCssClass:
            item.material_request_is_created_in_po === true
              ? "has-step-green"
              : "has-step-red",
        }
      } else {
        obj3 = {
          title: "Warehouse Cabang",
          content:
            item.material_request_is_accepted_by_warehouse === true
              ? `Material Request Has been Accepted by ${
                  item.sender.user_fullname
                } at ${this.dateSimple(
                  item.material_request_is_accepted_by_warehouse_date,
                  "YYYY-MM-DD",
                )}`
              : `Material Request Has not been Accepted By Warehouse`,
          stepCssClass:
            item.material_request_is_accepted_by_warehouse === true
              ? "has-step-green"
              : "has-step-red",
        }
        obj4 = {
          title: "Received",
          content:
            item.material_request_is_received === true
              ? `Material Request Has been Received By ${
                  item.receiver.user_fullname
                } at ${this.dateSimple(
                  item.material_request_is_received_date,
                  "YYYY-MM-DD",
                )}`
              : `Material Request Has not been Received`,
          stepCssClass:
            item.material_request_is_received === true
              ? "has-step-green"
              : "has-step-red",
        }
      }
      this.statusItems = []
      this.statusItems.push(obj1)
      this.statusItems.push(obj2)
      this.statusItems.push(obj3)
      this.statusItems.push(obj4)
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_DETAILS}${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            this.items = response.data.data
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Data success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Data failed",
                icon: "CheckIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    deliveryConfirmation() {
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
      const id = this.materialRequestId
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_MATERIAL_REQUEST_DELIVERY_CONFIRMATION}${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const url = `${process.env.VUE_APP_API_URL}temp/${response.data.data}`
            window.open(url, "_blank")
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Download Data failed",
                icon: "CheckIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    print() {
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
      const id = this.materialRequestId
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_MATERIAL_REQUEST}${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const url = `${process.env.VUE_APP_API_URL}temp/${response.data.data}`
            window.open(url, "_blank")
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Download Data failed",
                icon: "CheckIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    getOptionLabel(option) {
      return (option && option.label) || ""
    },
    putItem(val) {
      if (this.chosens.length > 0) {
        let same = false
        this.chosens.map(elem => {
          if (elem.value === val.value) {
            same = true
          }
        })
        if (same === false) {
          let item = null
          this.items.map(elem => {
            if (elem.value === val.value) {
              item = { label: elem.label, value: elem.value, qty: 1 }
            }
          })
          this.chosens.push(item)
        }
      } else {
        let item = null
        this.items.map(elem => {
          if (elem.value === val.value) {
            item = { label: elem.label, value: elem.value, qty: 1 }
          }
        })

        this.chosens.push(item)
      }
    },
    applyItem(val) {
      console.log(val.data)
    },
    getOptionKey(option) {
      return (option && option.value) || ""
    },
    inputSearch(search) {
      console.log(search)
    },
    removeRow(val) {
      this.chosens = this.chosens.filter(obj => obj.value !== val)
    },
    dateFormat(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    previous() {
      this.$router.push({ name: "apps-material-request-list" })
    },
    getFullUrl(picturePath) {
      return `${this.apiUrl}/upload/${picturePath}`
    },
    capitalizeWords(str) {
      // Split the string into words using spaces as the separator
      const words = str.split(" ")
      // Capitalize the first letter of each word and join them back together
      const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      // Join the capitalized words into a single string with spaces
      return capitalizedWords.join(" ")
    },
  },
}
</script>
